import { getClientInfo } from "../../../Utils/Common/utils";
import { postLoginLog, postLogin } from "../Services/LoginService";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_EXP,
  USER_DATA,
  INDEX_PAGE,
} from "../../../constants/applicationConstants";
import { setUserTenant } from "../Services/LoginService";
import { dexieCore } from "../../../Utils/Storage/IndexDB/dexieDb";

/**
 * createLoginLog
 * @param response
 */
export const createLoginLog = async ({ dataItem, response, encodedData }) => {
  let clientInfo = getClientInfo();
  let reqDetails = {
    Request: encodedData,
    Response: Object.fromEntries(
      Object.entries(response).filter(
        ([key]) => key !== "access_token" && key !== "refresh_token"
      )
    ),
  };
  let appLog = {
    UserId: response?.error ? dataItem?.username : response?.userId,
    ActivityCode: "LOGIN",
    RequestAllowed: response?.error ? false : true,
    RequestDisallowReason: response?.error
      ? JSON.stringify({
          StatusCode: JSON.parse(response?.error_description)?.StatusCode,
          StatusDesc: JSON.parse(response?.error_description)?.StatusDesc,
        })
      : "",
    Details: JSON.stringify(reqDetails),
    ...clientInfo,
  };
  postLoginLog({ data: appLog });
};
/**
 * login
 * @param response
 */
export const login = async ({ dataItem, updateUser, page, onLoginError }) => {
  let response = await postLogin({ dataItem });
  if (response && response?.error) {
    if (page && page !== "login") {
      onLoginError({ response });
    }
  } else {
    const data = response;

    sessionStorage.setItem(ACCESS_TOKEN, data.access_token);
    sessionStorage.setItem(REFRESH_TOKEN, data.refresh_token);
    sessionStorage.setItem(TOKEN_EXP, data.expires_in);
    sessionStorage.setItem(USER_DATA, JSON.stringify(data));
    updateUser(data);
    if (page === "social") {
      let configs = [
        {
          ConfigId: ACCESS_TOKEN,
          value: data.access_token,
        },
        {
          ConfigId: REFRESH_TOKEN,
          value: data.refresh_token,
        },
      ];
      await dexieCore.Configurations.bulkPut(configs);
    }

    await setUserTenant({ data });
    window.location.replace(INDEX_PAGE);
  }
  return {
    ...response,
    page: page,
  };
};
